import React from "react";
import styled from "styled-components";
import { COLOR, DEVICE } from "../../style";

type ComponentWithChild = {
  children: React.ReactChild | React.ReactChild[];
};

export const HomePromoContainer = styled.div<{ backgroundImage: string }>`
  position: relative;
  padding: 120px 0 0;
  min-height: 360px;
  overflow: hidden;
  text-align: center;
  background: linear-gradient(60deg, ${COLOR.PRIMARY} 0%, ${COLOR.PRIMARY_DARK} 100%);

  @media ${DEVICE.MD} {
    min-height: 550px;
    background-image: url(${(props) => props.backgroundImage});
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
  }
`;

export const HomePromoInner = styled.div`
  position: relative;
  max-width: 500px;
  margin: 0px auto 40px;
`;

export const PromoTitle = styled.h1`
line-height: 50px;
  font-size: 60px;
  color: ${COLOR.LIGHT};
  font-weight: 600;
  
  @media ${DEVICE.MD} {
    line-height: 70px;
    font-size: 80px;
  }
`;

export const PromoSubtitle = styled.p`
  font-size: 17px;
  color: ${COLOR.LIGHT};
  font-weight: 600;
  padding: 10px 0 30px;
  margin: 0;

  @media ${DEVICE.MD} {
    font-size: 20px;
    padding: 20px 0 30px;
  }
`;
