import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { COLOR } from "../../style";
import {
  TitleLight,
  SubheaderLight,
} from "../../styleguide/components/Typography";

import { bg1, step1, step2, step3 } from "./images";

const ProcessBackground = styled.div`
  background-color: ${COLOR.PRIMARY};
  background-image: url(${bg1});
  text-align: center;
  background-size: cover;
`;

const ProcessInner = styled(Container)`
  padding: 80px 0 40px;
`;

const StepsImg = styled.img`
  max-width: 75%;
  margin-top: 0px;
`;

export function Process() {
  return (
    <ProcessBackground>
      <ProcessInner>
        <TitleLight
          style={{ color: COLOR.SECONDARY, fontSize: 35, fontWeight: 600 }}
        >
          How does it work?
        </TitleLight>
        <SubheaderLight style={{ fontSize: 20, fontWeight: 600 }}>
          it couldn't be simpler!
        </SubheaderLight>
        <SubheaderLight>Make your will in three easy steps.</SubheaderLight>
        <div style={{ margin: "50px auto", maxWidth: 700 }}>
          <StepsImg alt="TODO:- insert alt text" src={step1} style={{ marginRight: "10%" }} />
          <StepsImg alt="TODO:- insert alt text" src={step2} style={{ marginLeft: "10%" }} />
          <StepsImg alt="TODO:- insert alt text" src={step3} />
        </div>
      </ProcessInner>
    </ProcessBackground>
  );
}
