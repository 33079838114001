import styled from "styled-components";
import { COLOR, DEVICE } from "../../style";

interface NavBarProps {
  isLight: boolean;
  isTransparent: boolean;
}

export const NavBar = styled.nav<NavBarProps>`
  background-color: ${({ isLight, isTransparent }) =>
    !isTransparent
      ? isLight
        ? COLOR.WHITE
        : COLOR.PRIMARY
      : "transparent"} !important;
  display: flex;
  width: 100%;
  min-height: 60px;
  box-shadow: ${({ isTransparent }) =>
    isTransparent ? "0" : "0px 2px 2px rgba(0,0,0,0.3)"};

  & .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .is-open .navbar-links {
    background-color: ${({ isLight }) => (isLight ? COLOR.LIGHT : COLOR.GRAY)};
  }
`;

export const NavBarBrand = styled.span`
  width: 120px;
  display: inline-block;
`;

export const NavBarToggle = styled.div`
  svg {
    height: 100%;
    width: 25px;
  }
  cursor: pointer;
  @media ${DEVICE.MD} {
    display: none;
  }
`;

export const NavBarToggleBlur = styled.div`
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  position: absolute;
`;

export const NavbarCollapse = styled.div`
  display: none;
  flex-grow: 1;

  .navbar-links {
    display: flex;
    flex-basis: auto;
    justify-content: flex-end;
    gap: 5px;
  }

  @media ${DEVICE.MD} {
    display: block;
  }

  &.is-open {
    display: block;
    height: 100%;

    .navbar-links {
      position: absolute;
      padding: 5px 15px 15px;
      margin-top: 60px;
      width: 100%;
      left: 0;
      flex-direction: column;
      z-index: 2;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    }
  }
`;

export const LogoImg = styled.img<{ isFixed?: boolean }>`
  height: ${({isFixed}) => isFixed ? '50px' : '60px'};
  width: auto;
  margin: 10px 0;

  @media ${DEVICE.MD} {
    height: ${({isFixed}) => isFixed ? '50px' : '80px'};
  }
`;
