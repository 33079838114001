import React from "react";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AppContext from "../../contexts/app";
import { StickyHeader } from "../../components/Header";
import Footer from "../../components/Footer";
import HomePromo from "../../components/HomePromo";
import { FeatureCard } from "../../components/Card";
import { COLOR } from "../../style";
import {
  Body2Light,
  Headline,
  TitleLight,
} from "../../styleguide/components/Typography";
import { Quote } from "./Quote";
import { Waves } from "../../components/Effects/Waves";
import { FAQ } from "../../components/FAQ";
import { Process } from "./Process";
import { bg2, icon1, icon2, icon3, icon4, icon5 } from "./images";
import { Curve } from "../../components/Effects/Curve";
import CommingSoonHeaderPromo from "../../components/HomePromo/CommingSoonHeaderPromo";

function HomePage() {
  const appContext = React.useContext(AppContext);
  const sectionRefs = React.useRef<{ [key: string]: HTMLElement | null }>({});
  const location = useLocation();

  React.useEffect(() => {
    sectionRefs.current = {};
  }, []);
  React.useEffect(() => {
    const currentHash = location.hash;
    const ref = sectionRefs.current[currentHash];

    if (ref) {
      ref.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <title>
          Islamic Wills in 20 minutes. Fully tax-efficient Wills -{" "}
          {appContext.state.companyName}
        </title>
      </Helmet>

      <StickyHeader />
      <CommingSoonHeaderPromo />
      <Quote
        quote="It is the duty of a Muslim who has something which is to be given
        as a bequest not to have it for two nights without
        having his will written down regarding it."
        author="Sahih-al-Bukhari"
      />

      <section
        ref={(ref) => {
          sectionRefs.current["#process"] = ref;
        }}
      >
        <Process />
      </section>
      <Curve isDown>
        <section
          ref={(ref) => {
            sectionRefs.current["#benefits"] = ref;
          }}
          style={{ background: COLOR.WHITE }}
        >
          <Container
            style={{
              paddingTop: "70px",
              paddingBottom: "70px",
              textAlign: "center",
            }}
          >
            <Row style={{ marginBottom: "40px" }}>
              <Col>
                <Headline
                  style={{
                    color: COLOR.SECONDARY,
                    fontWeight: 600,
                    fontSize: 35,
                  }}
                >
                  Why Choose Us?
                </Headline>
              </Col>
            </Row>
            <Row>
              <Col md style={{ marginTop: "10px" }}>
                <FeatureCard
                  Icon={() => (
                    <img alt="insert alt text" height="70px" src={icon1} />
                  )}
                  title="Fulfil an important religious duty"
                  description="If you die without leaving a Will you are deemed to have died ‘intestate’ and so your wealth will be distributed in accordance with the UK rules on intestacy – which do not apply the same criteria as those laid down by the Sharia."
                />
              </Col>
              <Col md style={{ marginTop: "10px" }}>
                <FeatureCard
                  Icon={() => (
                    <img alt="insert alt text" height="70px" src={icon2} />
                  )}
                  title="Gives you peace of mind"
                  description="Ensures that your wishes are followed avoiding unnecessary family disputes after you have passed away."
                />
              </Col>
              <Col md style={{ marginTop: "10px" }}>
                <FeatureCard
                  Icon={() => (
                    <img alt="insert alt text" height="70px" src={icon3} />
                  )}
                  title="Makes financial sense"
                  description="We use smart technology to save you hundreds of pounds with real support from experts to make sure we do it right. It is a quick and simple process to make a Will and it’s relatively inexpensive too!"
                />
              </Col>
            </Row>
          </Container>
        </section>
      </Curve>

      <div
        style={{
          backgroundImage: `url(${bg2})`,
          marginTop: "-50px",
          height: "300px",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      ></div>
      <section>
        <div></div>
      </section>

      <section
       ref={(ref) => {
        sectionRefs.current["#about"] = ref;
      }}
        style={{
          backgroundColor: COLOR.PRIMARY,
          padding: "40px 0 30px",
        }}
      >
        <Container>
          <Row>
            <Col md={2}></Col>
            <Col md={5}>
              <TitleLight
                style={{ fontSize: 30, fontWeight: 600, marginBottom: "24px" }}
              >
                We're aiming high
                <span style={{ color: COLOR.SECONDARY }}>...</span>
              </TitleLight>
              <Body2Light>
                We’ve made it our mission to help all UK-based Muslims to obtain
                legal and Sharia compliant wills by 2030.
              </Body2Light>
              <Body2Light>
                Designate legal guardians for your children, decide how your
                wealth and property will be distributed, and plan gifts for your
                family and those closest to you.
              </Body2Light>
              <Body2Light>
                Continue to be a positive example by donating to a charity of
                your choice or by leaving advice and guidance for your children.
              </Body2Light>
            </Col>
            <Col md={3} xs="2">
              <img alt="x" src={icon4} width="100%" />
            </Col>
          </Row>
        </Container>
      </section>

      <section
        ref={(ref) => {
          sectionRefs.current["#faq"] = ref;
        }}
      >
        <div style={{ backgroundColor: COLOR.LIGHT }}>
          <Container style={{ paddingBottom: "20px" }}>
            <Row>
              <Col md={8} style={{ paddingTop: "60px" }}>
                <Headline
                  style={{
                    paddingBottom: "30px",
                    color: COLOR.PRIMARY,
                    fontWeight: 600,
                  }}
                >
                  Frequently Asked Questions
                </Headline>
                <FAQ />
              </Col>
              <Col md xs="2" style={{ paddingTop: "60px" }}>
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    overflow: "hidden",
                    display: "flex",
                  }}
                >
                  <img alt="y" src={icon5} width="100%" style={{ alignSelf: "flex-end" }} />
                </div>
              </Col>
            </Row>
          </Container>
          <div style={{ backgroundColor: COLOR.SECONDARY }}>
            <div style={{ transform: "rotate(180deg)" }}>
              <Waves baseColor={COLOR.LIGHT} secondaryColor={COLOR.TERTIARY} />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default HomePage;
