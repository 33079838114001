import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import styled from "styled-components";
import { ThreeDotsVertical, X } from "react-bootstrap-icons";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import { COLOR } from "../../style";
import Button from "../../styleguide/components/Button";
import { logolgLight, logolgDark, logoSmLight, logoSmDark } from "../../images";
import {
  LogoImg,
  NavBar,
  NavBarBrand,
  NavbarCollapse,
  NavBarToggle,
  NavBarToggleBlur,
} from "./style";

interface HeaerProps {
  color?: "light" | "dark";
  transparent?: boolean;
  isFixed?: boolean;
}

interface StickyHeaderProps {
  offset?: number;
}

export const NavLink = styled(({ ...props }) => (
  <Button {...props} type="plain-dark" />
))`
  &,
  &:active,
  &:hover {
    color: ${({ isLight }) => (isLight ? COLOR.DARK : COLOR.LIGHT)} !important;
  }
`;

function NavLinks({
  isLoggedIn,
  isLight,
}: {
  isLoggedIn: boolean;
  isLight: boolean;
}) {
  return (
    <>
      <NavLink {...{ isLight, to: "/" }}>Home</NavLink>
      {isLoggedIn ? (
        <NavLink {...{ isLight, to: "/my-wills" }}>My Wills</NavLink>
      ) : (
        <NavLink {...{ isLight, to: "/login" }}>Login</NavLink>
      )}
      <NavLink {...{ isLight, to: "/#process" }}>Process</NavLink>
      <NavLink {...{ isLight, to: "/#benefits" }}>Benefits</NavLink>
      <NavLink {...{ isLight, to: "/#about" }}>About</NavLink>
      <NavLink {...{ isLight, to: "/#faq" }}>FAQs</NavLink>

      {isLoggedIn && <NavLink {...{ isLight, to: "/logout" }}>Logout</NavLink>}

      <Button href="tel:+4412345678910" color={"tertiary"}>
        Book a call
      </Button>
    </>
  );
}

export function Header({ color = "light", transparent, isFixed }: HeaerProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const isLight = isOpen || color === "light";
  const logoSrc = isFixed
    ? isLight ? logoSmLight : logoSmDark
    : isLight ? logolgLight : logolgDark;

  return (
    <NavBar isLight={isLight} isTransparent={!isOpen && !!transparent}>
      <Container>
        <Link to="/">
          <NavBarBrand>
            <LogoImg alt="logo" src={logoSrc} isFixed={isFixed} />
          </NavBarBrand>
        </Link>
        <NavbarCollapse className={`${isOpen ? "is-open" : ""}`} color={color}>
          <div className="navbar-links">
            <NavLinks isLight={isLight} isLoggedIn={false} />
          </div>
        </NavbarCollapse>
        <NavBarToggle onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <X color={isLight ? COLOR.PRIMARY : COLOR.LIGHT} />
          ) : (
            <ThreeDotsVertical color={isLight ? COLOR.PRIMARY : COLOR.LIGHT} />
          )}
        </NavBarToggle>
      </Container>
      {isOpen && <NavBarToggleBlur onClick={() => setIsOpen(false)} />}
    </NavBar>
  );
}

const StickyHeaderWrapper = styled.div`
  width: 100%;
  z-index: 2;
  transition: 1s;

  &.is-absolute {
    position: absolute;
    top: -50px;
  }
  &.is-fixed {
    position: fixed;
    top: 0;
  }
`;

export function StickyHeader({ offset = 150 }: StickyHeaderProps) {
  const [headerFixed, setHeaderFixed] = React.useState(false);

  useScrollPosition(
    ({ currPos }: any) => {
      if (!headerFixed && currPos.y < -1 * offset) {
        setHeaderFixed(true);
      }
      if (headerFixed && currPos.y > -1 * offset) {
        setHeaderFixed(false);
      }
    },
    [headerFixed, setHeaderFixed]
  );

  return (
    <StickyHeaderWrapper className={!headerFixed ? "is-absolute" : "is-fixed"}>
      {!headerFixed && <div style={{ marginTop: "50px" }} />}
      <Header
        color={!headerFixed ? "dark" : "light"}
        transparent={!headerFixed}
        isFixed={headerFixed}
      />
    </StickyHeaderWrapper>
  );
}

export default Header;
