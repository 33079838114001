import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLOR } from "../../../style";

type GetButtonTagProps = {
  href?: string;
  to?: string;
};

export type ButtonColor =
  | "plain"
  | "primary"
  | "secondary"
  | "tertiary"
  | "plain-dark"
  | "primary-dark"
  | "secondary-dark"
  | "tertiary-dark";

export type ButtonProps = {
  color?: ButtonColor;
  size?: 'small'|'large';
  disabled?: boolean;
  loading?: boolean;
  children?: any;
  onClick?: Function;
  to?: string;
  href?: string;
  block?: boolean;
  style?: React.CSSProperties;
  type?: string;
};

const buttonBGColorToColorMap: {[color: string]: string} = {
  'plain': 'transparent',
  'plain-dark': 'transparent',
  'primary': COLOR.PRIMARY,
  'tertiary': COLOR.TERTIARY,
  'primary-dark': COLOR.PRIMARY_LIGHT,
  'secondary': COLOR.SECONDARY,
  'secondary-dark': COLOR.SECONDARY_LIGHT,
  'tertiary-dark': COLOR.TERTIARY_LIGHT,
};

const buttonColorToColorMap: {[color: string]: string} = {
  'plain': COLOR.TERTIARY,
  'plain-dark': COLOR.TERTIARY_LIGHT,
  'primary': COLOR.LIGHT,
  'primary-dark': COLOR.DARK,
  'secondary': COLOR.LIGHT,
  'secondary-dark': COLOR.DARK,
  'tertiary': COLOR.LIGHT,
  'tertiary-dark': COLOR.DARK,
};

const buttonDisabledBGColorToColorMap: {[color: string]: string} = {
  'plain': 'transparent',
  'plain-dark': 'transparent',
  'primary': COLOR.GRAY_lIGHT,
  'primary-dark': COLOR.GRAY_lIGHT,
  'secondary': COLOR.GRAY_lIGHT,
  'secondary-dark': COLOR.GRAY_lIGHT,
  'tertiary': COLOR.GRAY_lIGHT,
  'tertiary-dark': COLOR.GRAY_lIGHT,
};

const buttonDisabledColorToColorMap: {[color: string]: string} = {
  'plain': COLOR.DARK,
  'plain-dark': COLOR.LIGHT,
  'primary': COLOR.DARK,
  'primary-dark': COLOR.LIGHT,
  'secondary': COLOR.DARK,
  'secondary-dark': COLOR.LIGHT,
  'tertiary': COLOR.DARK,
  'tertiary-dark': COLOR.LIGHT,
};

const buttonShadowToColorMap: {[color: string]: string} = {
  'plain': '0',
  'plain-dark': '0',
  'primary': `0px 8px 8px rgba(0,0,0,0.3)`,
  'primary-dark': `0px 8px 8px rgba(0,0,0,0.3)`,
  'secondary': `0px 8px 8px rgba(0,0,0,0.3)`,
  'secondary-dark': `0px 8px 8px rgba(0,0,0,0.3)`,
  'tertiary': `0px 8px 8px rgba(0,0,0,0.3)`,
  'tertiary-dark': `0px 8px 8px rgba(0,0,0,0.3)`,
};

const createButtonContainer = (element: any) => styled(element)`
  &, &:hover, &active {
    background-color: ${props => buttonBGColorToColorMap[props.color]};
    color: ${props => buttonColorToColorMap[props.color]};
    border: 0px;
    border-radius: 5px;
    font-weight: 600;
    font-size: ${props => props.size === 'large' ? '20px' : '16px'};
    text-align: center;
    min-width: ${({block}) => block ? '100%' : '70px'};
    padding: ${props => props.size === 'large' ? '7px 12px' : '8px 10px'};
    box-shadow: ${props => buttonShadowToColorMap[props.color]};
    transition: opacity 0.1s ease-in-out;
  }

  &:disabled {
    background-color: ${props => buttonDisabledBGColorToColorMap[props.color]};
    color: ${props => buttonDisabledColorToColorMap[props.color]};
    box-shadow: 0 0 0;
    opacity: 0.7;
  }

  &:active {
    opacity: 0.8;
  }
`;

const ButtonA = createButtonContainer('a');
const ButtonLink = createButtonContainer(Link);
const ButtonButton = createButtonContainer('button');

const getButtonTag = ({ href, to }: GetButtonTagProps) =>
  href ? ButtonA : to ? ButtonLink : ButtonButton;

// @component
export const Button = ({
  color = "plain",
  size = 'small',
  loading,
  children,
  ...restProps
}: ButtonProps) => {
  const ButtonTag: any = getButtonTag(restProps);

  return (
    <ButtonTag color={color} size={size} {...restProps}>
      <span>{children}</span>
    </ButtonTag>
  );
};

export default Button;
